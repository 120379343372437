import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  MobileStepper,
  Typography,
} from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import ActivityEditorDialog from "components/feed/ActivityEditor/ActivityEditorDialog";
import Close from "components/icons/Close";
import useMe from "hooks/useMe";

import BannerCardContent from "./BannerCardContent";
import CompletedCardContent from "./CompletedCardContent";
import DismissOrientationDialog from "./DismissOrientationDialog";
import MobileDownloadDialog from "./MobileDownloadDialog";
import ProgressBar from "./ProgressBar";

export type StepContent = {
  mediaUrl: string;
  title: string;
  text: string;
  buttonText?: string;
  hasSkip?: boolean;
  link?: string;
  stepCompleted?: boolean;
};
type Steps = {
  profile?: StepContent;
  discussion?: StepContent;
  expertPanel?: StepContent;
  download?: StepContent;
  completed?: StepContent;
};

export default function OrientationBanner() {
  const {
    membershipTier,
    name: { first },
  } = useMe();
  const { permExpertPanels } = useFlags();
  const [dismissModalOpen, setDismissModalOpen] = useState(false);
  const [mobileDownloadDialogOpen, setMobileDownloadDialogOpen] =
    useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isOrientationCompleted, setIsOrientationCompleted] = useState(true);
  const [isDismissedBanner, setIsDismissedBanner] = useState(false);
  const [activityEditorDialogOpen, setActivityEditorDialogOpen] =
    useState(false);
  const [stepsCompleted, setStepsCompleted] = useState(0);

  const [steps, setSteps] = useState<Steps>({
    profile: {
      title: "Make your profile stand out",
      text: "Update your profile to tell others about yourself, your work, and additional information.",
      buttonText: "Take me there",
      link: "/profile",
      mediaUrl:
        "https://storage.googleapis.com/cco-web-assets/onboarding/banner-images/onboarding-banner-profile-compressed.jpg",
    },
    discussion: {
      title: "Start a discussion",
      text: "Create a post to introduce yourself to your peers.",
      buttonText: "Get started",
      mediaUrl:
        "https://storage.googleapis.com/cco-web-assets/onboarding/banner-images/onboarding-banner-discussion-compressed.jpeg",
    },
    expertPanel: {
      title: "Contribute to an Expert Panel",
      text: "Answer questions that need your insights and get published!",
      buttonText: "Take me there",
      link: "/publishing/expert-panels?tab=open",
      mediaUrl:
        "https://storage.googleapis.com/cco-web-assets/onboarding/banner-images/onboarding-banner-eps-compressed.jpeg",
    },
    download: {
      title: "Download the app",
      text: "Did you know there's a mobile app? Download and log in to check it out!",
      buttonText: "Take me there",
      hasSkip: true,
      mediaUrl:
        "https://storage.googleapis.com/cco-web-assets/onboarding/banner-images/onboarding-banner-mobile-compressed.jpeg",
    },
  });

  // Conditionally remove expertPanel if permExpertPanels is false
  useEffect(() => {
    if (permExpertPanels !== true) {
      setSteps((prevSteps) => {
        delete prevSteps.expertPanel;
        return prevSteps;
      });
    }
  }, [permExpertPanels]);

  // Conditionally update profile step for premium members
  useEffect(() => {
    if (membershipTier === "premium") {
      setSteps((prevSteps) => ({
        ...prevSteps,
        profile: {
          title: "Make your profile the best it can be!",
          text: "Update your profile to tell others about yourself, learn about premium, and more with our experts.",
          buttonText: "Schedule a call",
          link: "/profile",
          stepCompleted: true,
          mediaUrl:
            "https://storage.googleapis.com/cco-web-assets/onboarding/banner-images/onboarding-banner-profile-compressed.jpg",
        },
      }));
    }
  }, [membershipTier]);

  // Dynamically adding the completed step if orientation is completed
  const allSteps = isOrientationCompleted ? { ...steps, completed: {} } : steps;
  const stepKeys = Object.keys(allSteps);
  const currentStepKey = stepKeys[activeStep];

  const handleNext = () => {
    setActiveStep((prevStep) => Math.min(prevStep + 1, stepKeys.length - 1));
  };
  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };
  const handleButtonClick = () => {
    if (currentStepKey === "discussion") {
      setActivityEditorDialogOpen(true);
    }
    if (currentStepKey === "download") {
      setMobileDownloadDialogOpen(true);
    }
  };

  const handleSkip = () => {
    setSteps((prevSteps) => ({
      ...prevSteps,
      [currentStepKey]: { ...prevSteps[currentStepKey], stepCompleted: true },
    }));
    setStepsCompleted((prev) => prev + 1);
    // If all steps are completed, mark orientation as completed and set active step to "completed"
    if (stepsCompleted + 1 === stepKeys.length - 1) {
      setIsOrientationCompleted(true);
      setActiveStep(stepKeys.indexOf("completed"));
    } else {
      setActiveStep(0);
    }
  };
  const handleOpenModal = () => setDismissModalOpen(true);
  const handleCloseModal = () => setDismissModalOpen(false);
  const handleConfirmModal = () => {
    setDismissModalOpen(false);
    setIsDismissedBanner(true);
  };
  if (isDismissedBanner) return null;

  return (
    <>
      <DismissOrientationDialog
        open={dismissModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmModal}
      />
      <MobileDownloadDialog
        open={mobileDownloadDialogOpen}
        onClose={() => setMobileDownloadDialogOpen(false)}
      />
      <ActivityEditorDialog
        open={activityEditorDialogOpen}
        onClose={() => setActivityEditorDialogOpen(false)}
      />
      <Card sx={{ backgroundColor: (theme) => theme.palette.grey[100], mb: 2 }}>
        <CardHeader
          action={
            <IconButton aria-label="close" onClick={handleOpenModal}>
              <Close fontSize="small" />
            </IconButton>
          }
          sx={{
            py: 1,
            textAlign: "center",
            "& .MuiCardHeader-action": {
              margin: "auto 0",
              alignSelf: "center",
            },
          }}
          title={
            <Typography variant="subtitle2">
              {`${first}, ${
                isDismissedBanner
                  ? "there's more to discover in your community!"
                  : "here's how to make the most out of your community"
              }`}
            </Typography>
          }
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            px: 3,
            pb: 2,
            width: "100%",
          }}
        >
          <ProgressBar maxSteps={stepKeys.length} value={stepsCompleted} />
          <Typography id="progress-status" variant="body2">
            {stepsCompleted}/{stepKeys.length} complete
          </Typography>
        </Box>

        <CardContent sx={{ p: 0, display: "grid" }}>
          {Object.keys(allSteps).map((stepKey, index) => (
            <Box
              sx={{
                gridRowStart: 1,
                gridColumnStart: 1,
                ...(index !== activeStep && {
                  visibility: "hidden",
                }),
              }}
            >
              {stepKeys[index] === "completed" ? (
                <CompletedCardContent />
              ) : (
                <BannerCardContent
                  // key={stepKey}
                  {...steps[stepKey]}
                  handleButtonClick={handleButtonClick}
                  handleSkip={handleSkip}
                />
              )}
            </Box>
          ))}
        </CardContent>

        <MobileStepper
          activeStep={activeStep}
          backButton={
            <Button
              disabled={activeStep === 0}
              size="small"
              startIcon={<KeyboardArrowLeft />}
              onClick={handleBack}
            >
              Back
            </Button>
          }
          nextButton={
            <Button
              disabled={activeStep === stepKeys.length - 1}
              endIcon={<KeyboardArrowRight />}
              size="small"
              onClick={handleNext}
            >
              Next
            </Button>
          }
          position="static"
          steps={stepKeys.length}
          sx={{ backgroundColor: "transparent", p: 1, width: "100%" }}
          variant="dots"
        />
      </Card>
    </>
  );
}
